
export default {
    state: {
        // 登录用户所拥有权限的全部菜单
        configDetail: {}
    },
    // 监听state更新
    getters: {

    },
    // 操作state
    mutations: {
        getConfigDetail(state, obj) {
            state.configDetail = obj.data
            console.log(' state.configDetail',  state.configDetail);
        }
    },
    actions: {}
}
