// 通用
import { request } from './request.js'


// 获取渠道
export function current (url,params,method) {
  return request({
    url,
		method:method?method:'GET',
    params
  })
}

// export default current