<template>
  <div id="app">
    <!-- <Home></Home> -->
    <router-view></router-view>
  </div>
</template>



<script>
// import Home from 'views/Home.vue'

// import { getAsideMenuData } from '/src/api/aside.js'

export default {
  components: {
    // Home,
  }

}
</script>

<style lang="scss" >
#app {
  height: 100vh;
}
/deep/ .el-image-viewer__wrapper {
  z-index: 3000 !important;
}

</style>
