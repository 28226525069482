import Vue from 'vue'
import VueRouter from 'vue-router'
// import { component } from "vue/types/umd";

Vue.use(VueRouter)

// 阻止router重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 如有不明白wechat 17722376869
// // 如果你的改了push还是没有生效，可以考虑改replace方法
// // 修改路由replace方法,阻止重复点击报错
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace (location) {
//   return originalReplace.call(this, location).catch(err => err);
// };

const Login = () => import('views/login/index.vue')

const Home = () => import('views/Home.vue')
// 仪表盘
const Dashboard = () => import('views/dashboard/Dashboard.vue')

// 订单管理
const OrderManagement = () => import('views/orderManagement/OrderManagement.vue')
const AccommodationOrder = () => import('views/orderManagement/children/accommodationOrder.vue')
const tourOrder = () => import('views/orderManagement/children/tourOrder.vue')
const privilegeOrder = () => import('views/orderManagement/children/privilegeOrder.vue')
const payOrder = () => import('views/orderManagement/children/payOrder.vue')
// 基础管理
const Basis = () => import('views/basis/Basis.vue')
const BrandManage = () => import('views/basis/children/BrandManage.vue')
const GoodsManage = () => import('views/basis/children/GoodsManage.vue')
const ScenicElements = () => import('views/basis/children/ScenicElements.vue')
const PrivilegeClassify = () => import('views/basis/children/PrivilegeClassify.vue')
const card = () => import('views/basis/children/card.vue')
const shareProfit = () => import('views/basis/children/shareProfit.vue')
const TourismClassify = () => import('views/basis/children/TourismClassify.vue')
const tourArea = () => import('views/basis/children/tourArea.vue')
const roomTopic = () => import('views/basis/children/roomTopic.vue')
// 组织架构
const Organize = () => import('views/organize/Organize')
const User = () => import('views/organize/children/User.vue')
const Role = () => import('views/organize/children/Role')
const Menulist = () => import('views/organize/children/Menulist')
const Power = () => import('views/organize/children/Power')
const adminUser = () => import('views/organize/children/adminUser')
const Supplier = () => import('views/organize/children/Supplier')
const supplierStream = () => import('views/organize/children/supplierStream')
const dataAgent = () => import('views/organize/children/dataAgent')
const dataAgentStream = () => import('views/organize/children/dataAgentStream')
// 内容管理
const ContentManage = () => import('views/contentManage/contentManage.vue')
const ArticleManage = () => import('views/contentManage/children/ArticleManage.vue')
const Banner = () => import('views/contentManage/children/Banner.vue')

// 产品管理
const ProductManage = () => import('views/productManage/ProductManage.vue')
const TourismProduct = () => import('views/productManage/children/TourismProduct.vue')
const HotelHomeStay_HomeType = () => import('views/productManage/children/HotelHomeStay_HomeType.vue')
const HotelHomeStay = () => import('views/productManage/children/HotelHomeStay.vue')
const privilegeGoods = () => import('views/productManage/children/privilegeGoods.vue')
const privilegeGoods_norms = () => import('views/productManage/children/privilegeGoods_norms.vue')
const ShopProductList = () => import('views/productManage/children/ShopProductList.vue')

// 商城模块
const shopMoudle = () => import('views/shopMoudle/shopMoudle.vue')
const shopOrder = () => import('views/shopMoudle/children/shopOrder.vue')
const shopOrderDetail = () => import('views/shopMoudle/children/shopOrderDetail.vue')
const shopAfterSale = () => import('views/shopMoudle/children/shopAfterSale.vue')
const logisticsManage = () => import('views/shopMoudle/children/logisticsManage.vue')
const shopManage = () => import('views/shopMoudle/children/shopManage.vue')
const shopRefund = () => import('views/shopMoudle/children/shopRefund.vue')
// 微爱汇
const weiaiAll = () => import('views/weiai/weiaiAll.vue')
const bankPoint = () => import('views/weiai/children/bankPoint.vue')
const weiaiUser = () => import('views/weiai/children/weiaiUser.vue')

const Setting = () => import('views/setting/Setting')
const Webset = () => import('views/setting/children/Webset')
// 会员管理
const Vipmanage = () => import('views/vipmanage/Vipmanage')
const Viplist = () => import('views/vipmanage/children/Viplist')
const VIPlevel = () => import('views/vipmanage/children/VIPlevel')
const attract = () => import('views/vipmanage/children/attract')
const MemberCategory = () => import('views/vipmanage/children/MemberCategory/MemberCategory.vue')
const MemberEquity = () => import('views/vipmanage/children/MemberEquity/MemberEquity.vue')
const MemberConfig = () => import('views/vipmanage/children/MemberConfig/MemberConfig.vue')

// 电竞会员
const EsportsMember = () => import('views/esportsMember/index')
const EsportsMember_manage = () => import('views/esportsMember/children/EsportsMember_manage')
const EsportsMember_level = () => import('views/esportsMember/children/EsportsMember_level')
const EsportsMember_config = () => import('views/esportsMember/children/EsportsMember_config')
const EsportsMember_birthday = () => import('views/esportsMember/children/EsportsMember_birthday')
const EsportsMember_legendary = () => import('views/esportsMember/children/EsportsMember_legendary')
const SetUPPackages = () => import('views/esportsMember/children/SetUPPackages')
const ConfigLevel = () => import('views/esportsMember/children/ConfigLevel')
const MarketingTools = () => import('views/esportsMember/children/MarketingTools/index.vue')
const MarketingTools_SharePromotion = () => import('views/esportsMember/children/MarketingTools/children/MarketingTools_SharePromotion')
const MarketingTools_coupon = () => import('views/esportsMember/children/MarketingTools/children/MarketingTools_coupon')
const MarketingTools_coupon_add = () => import('views/esportsMember/children/MarketingTools/children/MarketingTools_coupon_add')



// 财务管理
const finance = () => import('views/finance/finance')
const pay = () => import('views/finance/children/pay')
// 
const Comment = () => import('views/comment/Comment')
const comment_hotel = () => import('views/comment/children/comment_hotel')
const Comment_detail = () => import('views/comment/children/Comment_detail')
const Comment_shop = () => import('views/comment/children/comment_shop')

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/',
    redirect: {
      name: 'Login'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '',
    name: Home,
    component: Home,
    children: [{
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    // 订单管理
    {
      path: '/orderManagement',
      name: 'OrderManagement',
      // /orderManagement/accommodationOrder
      component: OrderManagement,
      children: [{
        path: 'accommodationOrder',
        name: 'accommodationOrder',
        component: AccommodationOrder
      },
      {
        path: 'tourOrder',
        name: 'tourOrder',
        component: tourOrder
      },
      {
        path: 'privilegeOrder',
        name: 'privilegeOrder',
        component: privilegeOrder
      },
      {
        path: 'payOrder',
        name: 'payOrder',
        component: payOrder
      }
      ]
    },
    // 基础设置
    {
      path: '/basis',
      name: Basis,
      component: Basis,
      children: [{
        path: 'brandManage',
        name: 'brandManage',
        component: BrandManage
      },
      {
        path: 'goodsManage',
        name: 'goodsManage',
        component: GoodsManage
      },
      {
        path: 'tourismClassify',
        name: 'tourismClassify',
        component: TourismClassify
      },
      {
        path: 'scenicElements',
        name: 'ScenicElements',
        component: ScenicElements
      },
      {
        path: 'privilegeClassify',
        name: 'privilegeClassify',
        component: PrivilegeClassify
      },
      {
        path: 'card',
        name: 'card',
        component: card
      },
      {
        path: 'shareProfit',
        name: 'shareProfit',
        component: shareProfit
      },
      {
        path: 'tourArea',
        name: 'tourArea',
        component: tourArea
      },
      {
        path: 'roomTopic',
        name: 'roomTopic',
        component: roomTopic
      }
      ]
    },
    // 会员管理
    {
      path: '/vipmanage',
      name: 'Vipmanage',
      component: Vipmanage,
      children: [{
        path: 'Viplist',
        name: 'Viplist',
        component: Viplist
      },
      {
        path: 'VIPlevel',
        name: 'VIPlevel',
        component: VIPlevel
      },
      {
        path: 'attract',
        name: 'attract',
        component: attract
      },

      {
        path: 'MemberCategory',
        name: 'MemberCategory',
        component: MemberCategory
      },
      {
        path: 'MemberConfig',
        name: 'MemberConfig',
        component: MemberConfig
      },
      {
        path: 'MemberEquity',
        name: 'MemberEquity',
        component: MemberEquity
      },
      ]
    },
    // 电竞会员
    {
      path: '/EsportsMember',
      name: 'EsportsMember',
      component: EsportsMember,
      children: [
        {
          path: 'EsportsMember_manage',
          name: 'EsportsMember_manage',
          component: EsportsMember_manage
        },
        {
          path: 'EsportsMember_level',
          name: 'EsportsMember_level',
          component: EsportsMember_level
        },
        {
          path: 'EsportsMember_config',
          name: 'EsportsMember_config',
          component: EsportsMember_config
        },
        {
          path: 'EsportsMember_birthday',
          name: 'EsportsMember_birthday',
          component: EsportsMember_birthday
        },
        {
          path: 'EsportsMember_legendary',
          name: 'EsportsMember_legendary',
          component: EsportsMember_legendary
        },
        {
          path: 'SetUPPackages',
          name: 'SetUPPackages',
          component: SetUPPackages
        },
        {
          path: 'ConfigLevel',
          name: 'ConfigLevel',
          component: ConfigLevel
        },
        {
          path: 'MarketingTools',
          name: 'MarketingTools',
          component: MarketingTools,
        },
        {
          path: 'MarketingTools_SharePromotion',
          name: 'MarketingTools_SharePromotion',
          component: MarketingTools_SharePromotion,
        },
        {
          path: 'MarketingTools_coupon',
          name: 'MarketingTools_coupon',
          component: MarketingTools_coupon,
        },
        {
          path: 'MarketingTools_coupon_add',
          name: 'MarketingTools_coupon_add',
          component: MarketingTools_coupon_add,
        }
      ]
    },


    // 组织架构
    {
      path: '/organize',
      name: 'Organize',
      component: Organize,
      children: [{
        path: 'user',
        name: 'User',
        component: User
      },
      {
        path: 'role',
        name: 'Role',
        component: Role
      },
      {
        path: 'menulist',
        name: 'Menulist',
        component: Menulist
      },
      {
        path: 'power',
        name: 'Power',
        component: Power
      },
      {
        path: 'adminUser',
        name: 'adminUser',
        component: adminUser
      },
      {
        path: 'supplier',
        name: 'supplier',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: Supplier
      },
      {
        path: 'dataAgent',
        name: 'dataAgent',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: dataAgent
      },
      {
        path: 'dataAgentStream',
        name: 'dataAgentStream',
        component: dataAgentStream
      },
      {
        path: 'supplierStream',
        name: 'supplierStream',
        component: supplierStream
      }
      ]
    },
    //
    {
      path: '/setting',
      name: 'Setting',
      component: Setting,
      children: [{
        path: 'webset',
        name: 'Webset',
        component: Webset
      }]
    },
    // 内容管理
    {
      path: '/contentManage',
      name: 'ContentManage',
      component: ContentManage,
      children: [{
        path: 'articleManage',
        name: 'ArticleManage',
        component: ArticleManage
      },
      {
        path: 'banner',
        name: 'Banner',
        component: Banner
      }
      ]
    },
    // 产品管理
    {
      path: '/productManage',
      name: 'ProductManage',
      component: ProductManage,
      children: [{
        path: 'tourismProduct',
        name: 'TourismProduct',
        component: TourismProduct
      },
      {
        path: 'hotelHomeStay',
        name: 'HotelHomeStay',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: HotelHomeStay
      },
      {
        path: 'HotelHomeStay_HomeType',
        name: 'HotelHomeStay_HomeType',
        component: HotelHomeStay_HomeType
      },
      {
        path: 'privilegeGoods',
        name: 'privilegeGoods',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: privilegeGoods
      },
      {
        path: 'privilegeGoods_norms',
        name: 'privilegeGoods_norms',
        component: privilegeGoods_norms
      },
      {
        path: 'shopProductList',
        name: 'shopProductList',
        component: ShopProductList
      }
      ]
    },
    // 商城模块
    {
      path: '/shopMoudle',
      name: 'shopMoudle',
      component: shopMoudle,
      children: [{
        path: 'shopOrder',
        name: 'shopOrder',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: shopOrder
      },
      {
        path: 'shopOrderDetail',
        name: 'shopOrderDetail',
        component: shopOrderDetail
      },
      {
        path: 'shopAfterSale',
        name: 'shopAfterSale',
        component: shopAfterSale
      },
      {
        path: 'logisticsManage',
        name: 'logisticsManage',
        component: logisticsManage
      },
      {
        path: 'shopManage',
        name: 'shopManage',
        component: shopManage
      },
      {
        path: 'shopRefund',
        name: 'shopRefund',
        component: shopRefund
      }
      ]
    },
    // 微爱汇
    {
      path: '/weiaiAll',
      name: 'weiaiAll',
      component: weiaiAll,
      children: [{
        path: 'bankPoint',
        name: 'bankPoint',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: bankPoint
      },
      {
        path: 'weiaiUser',
        name: 'weiaiUser',
        meta: {
          keepAlive: true, // 此组件需要被缓存
          isBack: false // 用于判断上一个页面是哪个
        },
        component: weiaiUser
      }
      ]
    },
    //财政管理
    {
      path: '/finance',
      name: 'finance',
      component: finance,
      children: [{
        path: 'pay',
        name: 'pay',
        component: pay
      }]
    },
    {
      path: '/Comment',
      name: 'Comment',
      component: Comment,
      children: [{
        path: 'comment_hotel',
        name: 'comment_hotel',
        component: comment_hotel
      },
      {
        path: 'Comment_detail',
        name: 'Comment_detail',
        component: Comment_detail
      },
      {
        path: 'Comment_shop',
        name: 'Comment_shop',
        component: Comment_shop
      }
      ]
    },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {

//   console.log("to", to)
//   console.log("from", from)
//   next()
// })
export default router
