import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import vcolorpicker from 'vcolorpicker'
// 全局配置
import '@/assets/css/normalize.css'
import '@/assets/scss/basis.scss'
// 封装的全局 -- 方法
import globalDeepCloneData from 'common/globalDeepCloneData.js'
// 引入mock
// import '@/mock'


import { current } from "./api/current"


// 导入elementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入Vue Amap地图组件
import aMap from 'vue-amap'
Vue.prototype.my_request = current;//在原型上添加网络请求方法，this.my_request

// 颜色选择器
Vue.use(vcolorpicker)
Vue.use(ElementUI)
Vue.use(globalDeepCloneData)

Vue.config.productionTip = false

// vue-amap 高德地图组件
Vue.use(aMap)
aMap.initAMapApiLoader({
  key: 'e750d1ea3a84bae92cfd8f93313c01ac', // 你的key
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],
  v: '1.4.4', // 版本
  uiVersion: '1.0' // ui版本
})
new Vue({
  router,
  store,
  history,
  render: h => h(App)
}).$mount('#app')
// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
